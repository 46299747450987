<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>学习任务管理</el-breadcrumb-item>
        <el-breadcrumb-item>学习管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-header">
      <div></div>
      <div class="yt-header-toolbar">
        <el-select v-model="learnTaskType" placeholder="是否有学习任务" class="yt-search-select" clearable>
          <el-option v-for="item in learnTaskTypeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <el-button type="primary" size="small" @click="toSetTaskByUserIds(false)" style="width: 140px;">
          <div>
            <i class="el-icon-circle-plus-outline" />
            <p>设置学习任务</p>
          </div>
        </el-button>
        <el-button size="small" @click="toClearTaskByUserIds(false)" style="width: 140px;">
          <div>
            <YTIcon :href="'#icon-shanchu'" />
            <p>清除学习任务</p>
          </div>
        </el-button>
      </div>
    </div>
    <div class="yt-container">
      <div class="question-bank-content">
        <el-menu class="yt-menu" :default-active="activeIndex" mode="horizontal" @select="selectMenu">
          <el-menu-item index="0">
            <div class="icon">
              <YTIcon :href="'#icon-tiku2'" style="height: 12px"></YTIcon>
            </div>
            部门选择
          </el-menu-item>
          <!--          <el-menu-item index="1">-->
          <!--            <div class="icon">-->
          <!--              <YTIcon :href="'#icon-fenlei'" style="height: 12px"></YTIcon>-->
          <!--            </div>-->
          <!--            标签-->
          <!--          </el-menu-item>-->
        </el-menu>
        <template v-if="activeIndex === '1'">
          <el-input v-model="keyword" class="yt-search search" placeholder="通过名称搜索标签" @keydown.enter.native="searchTags">
            <i slot="suffix" class="el-input__icon el-icon-search" @click="searchTags"></i>
          </el-input>
        </template>
        <div class="tree has-tool" :class="{ 'has-search': activeIndex === '1' }">
          <el-tree
            ref="tree"
            :data="treeData"
            node-key="departmentId"
            :highlight-current="true"
            :filter-node-method="filterNode"
            @node-click="handleNodeClick"
            default-expand-all
          >
            <div slot-scope="{ node, data }" class="tree-node">
              <el-tooltip effect="dark" :content="data.name" :disabled="data.name.length < 10" placement="top-start">
                <div class="el-tree-node__label">{{ data.name }}</div>
              </el-tooltip>
              <div v-if="data" class="tools">
                <template v-if="activeIndex === '0'">
                  <span class="tool-button" @click.stop="toSetTaskByDeptId(data)">设置</span>
                  <span class="tool-button" @click.stop="toClearTaskByDeptId(data)">清除</span>
                </template>
                <template v-else>
                  <span class="tool-button" @click.stop="toSetTaskByTagId(data)">设置</span>
                  <span class="tool-button" @click.stop="toClearTaskByTagId(data)">清除</span>
                </template>
              </div>
            </div>
          </el-tree>
        </div>
      </div>
      <div class="yt-resize">
        ⋮
      </div>
      <div class="question-content">
        <el-table
          ref="staffTable"
          :data="staffList"
          v-loading="staffLoading"
          element-loading-text="加载中"
          class="yt-table"
          height="calc(100% - 35px)"
          @selection-change="selectChange"
          @row-click="toDetail"
        >
          <el-table-column width="31" type="selection" align="center" />
          <el-table-column label="姓名" prop="userName" show-overflow-tooltip />
          <el-table-column label="岗位" prop="postName" show-overflow-tooltip />
          <el-table-column label="标签" prop="tag" show-overflow-tooltip />
          <el-table-column label="总课程数" prop="totalStudyNum" show-overflow-tooltip />
          <el-table-column label="参加课程数" prop="studyingNum" show-overflow-tooltip />
          <el-table-column label="任务进度" show-overflow-tooltip>
            <template slot-scope="scope">
              <span :style="scope.row.totalProgress < 60 ? 'color: red' : 'color: darkseagreen'">{{ totalProgress(scope.row.totalProgress) }}%</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120px">
            <template slot-scope="scope">
              <span class="tool-button" @click.stop="toSetTaskByUserIds(true, scope.row)">设置</span>
              <span class="tool-button" style="margin-right: 10px" @click.stop="toClearTaskByUserIds(true, scope.row)">清除</span>
              <span class="tool-button" @click.stop="toDetail(scope.row)">详情</span>
            </template>
          </el-table-column>
        </el-table>
        <Page
          class="yt-page"
          :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
          :total="total"
          :current="pageNum + 1"
          :page-size-opts="[10, 20, 40, 100]"
          :page-size="pageSize"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
          show-elevator
          show-sizer
          show-total
        />
      </div>
    </div>
    <ConfirmDialog ref="batchSetTaskDialog" title="批量设置学习任务" @on-ok="batchSetTask">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        确认要给{{ activeDeptName }}部门下所有员工设置学习任务吗？
      </div>
    </ConfirmDialog>
    <ConfirmDialog ref="batchClearTaskDialog" title="批量清除学习任务" @on-ok="clearTaskByDeptId">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        确认要清除{{ activeDeptName }}部门下所有员工学习任务吗？
      </div>
    </ConfirmDialog>
    <ConfirmDialog ref="clearTaskDialog" title="清除学习任务" @on-ok="clearTaskByUserIds">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        确认要清除所选员工学习任务吗？
      </div>
    </ConfirmDialog>
    <learnTask ref="deptSetTaskDialog" @refresh="getStaffList" />
  </div>
</template>

<script>
import departmentApi from '@/api/department'
import YTIcon from '@components/common/YTIcon'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
import learnTask from './type/learnTask'
import learn from '@/api/learnTask'
import tagApi from '@/api/tag'

export default {
  name: 'LearningList',
  components: { YTIcon, ConfirmDialog, learnTask },
  data() {
    return {
      activeIndex: '0',
      activeDeptName: '',
      activeDeptId: null,
      activeTagId: null,
      batchSetDeptId: null,
      batchSetTagId: null,
      treeData: [],
      learnTaskTitle: '',
      learnTaskVisible: false,
      learnTaskType: 0,
      learnTaskTypeOptions: [
        {
          label: '全部',
          value: 0
        },
        {
          label: '有学习任务',
          value: 1
        },
        {
          label: '无学习任务',
          value: 2
        }
      ],
      total: 0,
      pageNum: 0,
      pageSize: 10,
      staffList: [],
      staffLoading: false,
      staffSelection: [],
      keyword: ''
    }
  },
  computed: {
    totalProgress() {
      return totalProgress => {
        if (totalProgress === undefined || totalProgress === null) {
          return 0
        }
        const res = Math.round(totalProgress * 10000) / 100
        return res > 100 ? 100 : res
      }
    }
  },
  created() {
    this.getDepartmentData()
    this.$nextTick(() => {
      this.$dragDiv()
    })
  },
  methods: {
    getDepartmentData() {
      this.treeData = []
      departmentApi.getDepartmentsTree().then(res => {
        this.activeDeptId = res.res.departmentId
        this.treeData.push({ ...res.res })
        if (this.activeDeptId) {
          this.getStaffList()
          this.setCurrentKey()
        }
      })
    },
    getTagData() {
      this.treeData = []
      tagApi.searchTagByTypeId({ typeId: 4 }).then(res => {
        if (res.res && res.res.length > 0) {
          this.activeTagId = res.res[0].tagId
          this.treeData = res.res.map(item => {
            return {
              ...item,
              departmentId: item.tagId //仅用作树节点高亮
            }
          })
          this.getStaffList()
          this.setCurrentKey()
        }
      })
    },
    getStaffList() {
      this.staffLoading = true
      const searchStudyTaskDTO = {
        departmentId: this.activeDeptId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        type: this.learnTaskType,
        tagId: this.activeTagId
      }
      if (this.activeIndex === '0') {
        learn.getAllTask(searchStudyTaskDTO).then(res => {
          this.total = res.res.total
          this.staffList = res.res.data
          this.staffLoading = false
        })
      } else {
        learn.getTaskByTagId(searchStudyTaskDTO).then(res => {
          this.total = res.res.total
          this.staffList = res.res.data
          this.staffLoading = false
        })
      }
    },
    reGetStaffList() {
      this.pageNum = 0
      this.getStaffList()
    },
    setCurrentKey() {
      this.$nextTick(() => {
        this.$refs['tree'].setCurrentKey(this.activeIndex === '0' ? this.activeDeptId : this.activeTagId)
      })
    },
    selectMenu(index) {
      this.activeIndex = index
      this.staffSelection = []
      this.staffList = []
      this.pageNum = 0
      this.pageSize = 10
      this.total = 0
      if (index === '0') {
        this.getDepartmentData()
      } else {
        this.getTagData()
      }
    },
    searchTags() {
      this.$refs.tree.filter(this.keyword)
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    handleNodeClick(data) {
      if (this.activeIndex === '0') {
        this.activeDeptId = data.departmentId
        this.reGetStaffList()
      } else {
        this.activeTagId = data.tagId
        this.reGetStaffList()
      }
    },
    toSetTaskByDeptId(data) {
      this.activeDeptName = data.name
      this.batchSetDeptId = data.departmentId
      this.$refs.batchSetTaskDialog.open()
    },
    toSetTaskByTagId(data) {
      this.activeDeptName = data.name
      this.batchSetTagId = data.tagId
      this.$refs.batchSetTaskDialog.open()
    },
    batchSetTask() {
      //批量设置部门下学习任务
      if (this.activeIndex === '0') {
        this.$refs.deptSetTaskDialog.open('dept', this.batchSetDeptId)
      } else {
        this.$refs.deptSetTaskDialog.open('tag', this.batchSetTagId)
      }
    },
    toClearTaskByDeptId(data) {
      this.activeDeptName = data.name
      this.batchSetDeptId = data.departmentId
      this.$refs.batchClearTaskDialog.open()
    },
    toClearTaskByTagId(data) {
      this.activeDeptName = data.name
      this.batchSetTagId = data.tagId
      this.$refs.batchClearTaskDialog.open()
    },
    clearTaskByDeptId() {
      //批量清除部门下学习任务
      if (this.activeIndex === '0') {
        learn.batchClearDepartById(this.batchSetDeptId).then(res => {
          if (res.res === 'success') {
            this.$message.success('学习任务清除成功')
            this.reGetStaffList()
          }
        })
      } else {
        learn.clearTaskByTagId(this.batchSetTagId).then(res => {
          if (res.res === 'success') {
            this.$message.success('学习任务清除成功')
            this.reGetStaffList()
          }
        })
      }
    },
    selectChange(val) {
      this.staffSelection = val.map(item => {
        return item.userId
      })
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      this.getStaffList()
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize
      this.getStaffList()
    },
    toSetTaskByUserIds(isSingle, data) {
      if (isSingle) {
        this.$refs.deptSetTaskDialog.open('user', null, [data.userId])
      } else {
        if (this.staffSelection.length === 0) {
          this.$message.warning('请先勾选要设置的员工')
          return
        }
        this.$refs.deptSetTaskDialog.open('user', null, this.staffSelection)
      }
    },
    toClearTaskByUserIds(isSingle, data) {
      if (isSingle) {
        this.staffSelection = [data.userId]
      } else {
        if (this.staffSelection.length === 0) {
          this.$message.warning('请先勾选要清除的员工')
          return
        }
      }
      this.$refs.clearTaskDialog.open()
    },
    clearTaskByUserIds() {
      const payload = {
        userIds: this.staffSelection
      }
      learn.batchClearBySelectIds(payload).then(res => {
        if (res.res) {
          this.$message.success('学习任务清除成功')
          this.getStaffList()
          this.staffSelection = []
        }
      })
    },
    toDetail(row) {
      this.$router.push({
        name: 'taskDetail',
        params: {
          userId: row.userId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables';
@import '~@/theme/dragDiv';
.yt-main {
  color: #000000;
  .yt-header {
    .el-button {
      width: 80px;
    }
  }
  .search {
    margin: 12px 0;
    padding: 0 10px;
    height: 32px;
    ::v-deep .el-input__suffix {
      right: 15px;
    }
  }
  .yt-container {
    padding: 10px 10px;
    .flexStyle(flex, space-between);
    .question-bank-content {
      background-color: #ffffff;
      min-width: 280px;
      height: 100%;
      ::v-deep .el-dropdown-menu__item {
        height: 28px;
        line-height: 28px;
        font-size: @small;
        font-weight: bold;
        &:hover {
          color: #448bff;
          background-color: #edf4ff;
        }
      }
      .yt-menu {
        .flexStyle(flex, space-between);
        height: 50px;
        padding: 6px 20px 0;
        margin: 0 !important;
        border-bottom: 1px solid #e2e4e8 !important;
        &:before {
          display: none;
        }
        &:after {
          display: none;
        }
        .el-menu-item {
          width: 45%;
          margin: 0 !important;
          color: #dbdbdb !important;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            color: #000000 !important;
            .icon {
              background-color: #448bff;
            }
          }
        }
        .is-active {
          color: #000000 !important;
          border-bottom: 4px solid #409eff;
          .icon {
            background-color: #448bff;
          }
        }
        .icon {
          .flexStyle(flex, center);
          width: 20px;
          height: 20px;
          background-color: #dbdbdb;
          border-radius: 50%;
          margin-right: 6px;
          svg {
            width: 14px;
            height: 14px;
            color: #ffffff;
            border-radius: unset;
            margin-right: 0;
          }
        }
        &.is-platform {
          justify-content: center;
          .el-menu-item {
            border-bottom: none;
          }
        }
      }
      .tree {
        margin-top: 10px;
        height: calc(100% - 60px);
        overflow: auto;
        .el-tree-node__content {
          height: 29px;
          .el-tree-node__expand-icon {
            color: #282c3d;
          }
          .is-leaf {
            color: transparent !important;
          }
          .el-tree-node__label {
            color: #000000;
            font-weight: bold;
            font-size: @small;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          &:hover {
            background-color: #f0f0f0;
            .tools {
              background-color: #f0f0f0;
            }
          }
        }
        .is-current {
          & > .el-tree-node__content {
            background-color: #f0f0f0;
          }
        }
        .el-tree-node__children {
          .el-tree-node__label {
            font-weight: 500;
          }
        }
        &.has-search {
          height: calc(100% - 116px);
          margin-top: 0;
        }
        &.has-tool {
          .tree-node {
            .flexStyle(flex, space-between);
            width: calc(100% - 24px);
            height: 100%;
            position: relative;
            &:hover {
              .tools {
                display: block;
              }
            }
          }
          .el-tree-node__label {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .tools {
            display: none;
            position: absolute;
            right: 0;
            background-color: #f5f7fa !important;
            padding-right: 5px;
            span {
              margin-left: 5px;
              &:hover {
                color: #448bff;
              }
            }
            &:hover {
              display: block;
            }
          }
        }
      }
    }
    .question-content {
      background-color: #ffffff;
      width: calc(100% - 290px);
      height: 100%;
      padding: 20px;
    }
  }
}
.yt-resize {
  width: 5px;
  height: 50px;
  position: relative;
  border-radius: 5px;
  background-color: white;
  display: flex;
  align-items: center;
  cursor: col-resize;
}
.yt-resize:hover {
  background: #c6c6c6;
}
</style>
