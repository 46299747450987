<template>
  <el-dialog
    class="taskModal yt-dialog yt-dialog-medium"
    :title="dialogTitle"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <div class="dialog-content">
      <div class="left">
        <Tree :data="deptCourseList" @on-check-change="checkCourse" show-checkbox />
      </div>
      <div class="right">
        <ul>
          <li>设置学习完成任务条件</li>
          <li>
            <span><b style="color: #ff0000">*</b>课程学习百分比设置(%)</span>
            <slider v-model="course" show-input />
          </li>
          <li>
            <span style="display: flex;">
              <!--                <b style="color: #ff0000">*</b>-->
              测试及格分设置
              <!--              <checkbox v-model="isSelected" />-->
            </span>
            <InputNumber style="margin-top: 12px" :max="100" :min="0" v-model="passPoint" />
          </li>
          <li style="border-bottom: none">
            <p>测试次数</p>
            <InputNumber :max="5" :min="0" v-model="testNum" />
          </li>
        </ul>
      </div>
    </div>
    <div class="footer" slot="footer">
      <el-button class="button" @click="visible = false">取消</el-button>
      <el-button class="button" type="primary" :loading="loading" @click="submitAdd">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import course from '@/api/course'
import learn from '@/api/learnTask'
export default {
  name: 'learnTask',
  data() {
    return {
      isSelected: false,
      course: 75,
      passPoint: 60,
      testNum: 1,
      courseList: [],
      deptCourseList: [],
      savePoints: 0,
      visible: false,
      mode: null,
      deptId: null,
      tagId: null,
      modes: {
        dept: {
          title: '批量设置部门学习任务',
          add: this.addDeptLearnTask
        },
        user: {
          title: '设置学习任务',
          add: this.addUserLearnTask
        },
        tag: {
          title: '批量设置标签学习任务',
          add: this.addTagLearnTask
        }
      },
      loading: false,
      userIds: []
    }
  },
  // watch: {
  //   isSelected: function(val) {
  //     if (val) {
  //       this.savePoints = this.passPoint
  //       this.passPoint = 60
  //     } else {
  //       this.passPoint = this.savePoints
  //     }
  //   }
  // },
  computed: {
    dialogTitle: function() {
      if (this.mode === null) {
        return ''
      } else {
        return this.modes[this.mode].title
      }
    }
  },
  methods: {
    open(mode, deptOrTagId = null, userIds = []) {
      this.getAllDepart()
      this.visible = true
      this.mode = mode
      if (mode === 'dept') {
        this.deptId = deptOrTagId
      } else {
        this.tagId = deptOrTagId
      }
      this.userIds = userIds
    },
    close(done) {
      done()
    },
    closed() {
      this.loading = false
      this.mode = null
      this.deptId = null
      this.tagId = null
      this.userIds = []
      this.courseList = []
      this.deptCourseList = []
    },
    checkCourse(course) {
      this.courseList = course
    },
    submitAdd() {
      this.loading = true
      if (this.courseList.length === 0) {
        this.deptCourseList.map(item => {
          if (item.checked === true) {
            this.courseList.push(item)
          }
        })
      }
      if (this.courseList.length <= 0) {
        return this.$message({ message: '分类不可为空', type: 'warning' })
      }
      const payLoad = {
        IsUpTask: this.mode === 'update',
        studyTaskDTOS: this.courseList
          .filter(c => c.courseId)
          .map(c => {
            return {
              courseId: c.courseId,
              examMaxCount: this.testNum,
              passExamScore: this.passPoint,
              passProgress: this.course
            }
          })
      }
      // if (this.studyTaskDTOS.length === 0)
      //   return this.$message({ message: '请选择岗位下的课程', type: 'warning' })
      this.modes[this.mode].add(payLoad)
    },
    getAllDepart() {
      const id = 0
      course.getCategoryTree(id).then(res => {
        this.deptCourseList.push({
          id: 0,
          title: '所有课程',
          children: []
        })
        this.getAllDepartCourse({ id: 0 })
        res.res.forEach(item => {
          this.deptCourseList.push({
            id: item.id,
            title: item.name,
            children: []
          })
          this.getAllDepartCourse(item)
        })
      })
    },
    getAllDepartCourse(node) {
      course.getCategoryCoursesAll(node.id).then(res => {
        const course = res.res.map(item => {
          return {
            courseId: item.courseId,
            title: item.name
          }
        })
        this.deptCourseList.map(depart => {
          if (depart.id === node.id) {
            depart.children = course
            if (course.length === 0) {
              depart.disabled = true
            }
          }
        })
      })
    },
    addDeptLearnTask(departmentAddStudyTaskDTO) {
      learn
        .addLearnTaskByDepart(this.deptId, departmentAddStudyTaskDTO)
        .then(res => {
          if (res.res === 'success') {
            this.$message.success('添加成功')
            this.visible = false
            this.$emit('refresh')
          } else {
            this.loading = false
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    addUserLearnTask(departmentAddStudyTaskDTO) {
      departmentAddStudyTaskDTO.userIds = this.userIds
      learn
        .addLearnTask(departmentAddStudyTaskDTO)
        .then(res => {
          if (res.code === 0) {
            this.$message.success('添加成功')
            this.visible = false
            this.$emit('refresh')
          } else {
            this.loading = false
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    addTagLearnTask(payload) {
      learn
        .setTaskByTagId(this.tagId, payload)
        .then(res => {
          if (res.res === 'success') {
            this.$message.success('添加成功')
            this.visible = false
            this.$emit('refresh')
          } else {
            this.loading = false
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.taskModal {
  .title {
    font-size: 20px;
    line-height: 42px;
    color: #3a4e64;
    text-align: center;
  }
  .dialog-content {
    display: flex;
    width: 100%;
    height: 100%;
    text-align: left;
    .left {
      overflow-y: auto;
      width: 433px;
      height: 389px;
      border: 1px solid #e4e9ef;
      margin-right: 17px;
      border-radius: 4px;
      padding: 16px 12px;
    }
    .right {
      flex: auto;
      height: 100%;
      border: 1px solid #e4e9ef;
      border-radius: 4px;
      /*padding: 22px;*/
      ul {
        list-style: none;
        li {
          font-size: 14px;
          line-height: 28px;
          color: #333;
          height: 109px;
          padding: 12px 22px;
          border-bottom: 1px solid #eaedf1;
        }
        li:first-child {
          height: 60px;
          font-size: 16px;
          line-height: 21px;
          color: #3a4e64;
        }
      }
    }
  }
  .footer {
    margin-top: 24px;
    text-align: center;
    height: 40px;
    button {
      width: 90px;
      height: 36px;
    }
    button:first-child {
      margin-right: 20px;
    }
  }
}
</style>
